import WalletConnect from "@walletconnect/client";

window.WalletConnect = WalletConnect;

import QRCodeModal from "@walletconnect/qrcode-modal";

window.QRCodeModal = QRCodeModal;

/*
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

window.Web3 = Web3;
window.WalletConnectProvider = WalletConnectProvider;
window.Web3Modal = Web3Modal;
*/