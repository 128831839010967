// Global
import "./modules/bootstrap";
import "./modules/theme";
//import "./modules/dragula";
//import "./modules/feather";
import "./modules/moment";
import "./modules/sidebar";
//import "./modules/toastr";
//import "./modules/user-agent";

//TrustWallet
import "./modules/walletconnect";

// Charts
import "./modules/chartjs";
import "./modules/apexcharts";

// Forms
import "./modules/daterangepicker";
//import "./modules/datetimepicker";
//import "./modules/fullcalendar";
//import "./modules/markdown";
import "./modules/mask";
//import "./modules/quill";
import "./modules/select2";
//import "./modules/validation";
//import "./modules/wizard";

// Maps
//import "./modules/vector-maps";

// Tables
//import "./modules/datatables";

import "./modules/splide";

import intlTelInput from 'intl-tel-input';

import { gsap } from "gsap";
window.gsap = gsap;

//Utils
import "./modules/sprintf";

$(function() {
	$('.show-password').on('click', function() {
		$(this).toggleClass("fa-eye fa-eye-slash");
		var input = $($(this).attr("toggle"));
		if (input.attr("type") == "password") {
			input.attr("type", "text");
		} else {
			input.attr("type", "password");
		}
	});

	const phone = document.querySelector("#phone");

	if (phone !== null) {
	    const phoneIsInvalid = phone.classList.contains('is-invalid');
	    const intl = intlTelInput(phone, {
	        nationalMode: false,
	        autoHideDialCode: true,
	        separateDialCode: true,
	        initialCountry: 'ua',
	        localizedCountries: {'ua': 'Україна', 'kz': 'Казахстан', 'ru': 'Россия'},
	        preferredCountries: [],
	        customContainer: (phoneIsInvalid ? 'is-invalid' : ''),
	        hiddenInput: phone.name
	    });

	    intl.getNumber = function () {
	        const dialCode = this.getSelectedCountryData()?.dialCode ?? '7';
            const number = phone.value.replace('/^\+' + dialCode + '/', '').trim();
	        let res = dialCode + number;

	        return number.length ? '+' + res.replace('/[^0-9]/', '') : null;
	    }

	    intl.countryList.style.width = phone.clientWidth + 'px';

	    phone.addEventListener('keydown', (event) => {
	        if (event.key?.length === 1 && event.key.search(/[A-Za-zА-Яа-я]/g) !== -1) {
	            event.preventDefault();
	        };
	    });
	}
});
